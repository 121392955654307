import { createRouter, createWebHistory } from 'vue-router';
//import QRScan from '@/views/QRScan.vue'

const routes = [
    {
        path: '/:uniqueId',
        name: 'QRScan',
        //component: QRScan
        component: () => import('../views/QRScan.vue')
    },
];

const router = createRouter({
    history: createWebHistory(),
    //base: process.env.BASE_URL,
    routes
});

export default router;
