import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import BootstrapVueNext from 'bootstrap-vue-next';
import 'bootstrap/dist/js/bootstrap.bundle'

import 'bootstrap/dist/css/bootstrap.css';


createApp(App)
    .use(router)
    .use(BootstrapVueNext)
    .mount('#app')
